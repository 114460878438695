import React from "react";
import * as storeActions from "../../Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import './loader.css';

const Loader = (props) => {
    
    const { isLoading } =  props;

    return(
        <div className={isLoading ? 'loadingPanel d-block' : 'loadingPanel d-none'}/>
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: {
        storeActions: bindActionCreators(storeActions, dispatch),
    },
});

export default connect(null, mapDispatchToProps)(Loader);