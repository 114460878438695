import React from "react";
import { Navigate } from "react-router-dom";
// import jwt_decode from "jwt-decode";
// const sign = require("jwt-encode");

export const getAccessToken = () => {
    return localStorage.getItem("accessTokenDD");
  };
  
  export const clearAccessToken = () => {
    localStorage.removeItem("accessTokenDD");
    localStorage.removeItem("userDetail");
    localStorage.clear();
  };
  
  export const setAccessToken = (token) => {
    localStorage.setItem("accessTokenDD", token);
  };
  
  export const isLoggedIn = () => {
    const accessToken = getAccessToken();
    return !!accessToken;
  };
  
  export const RequireAuth = ({ children }) => {
    return isLoggedIn() ? children : <Navigate to="/Login" replace />;
  };
  
  export const LoginedIn = ({ children }) => {
    return !isLoggedIn() ? children : <Navigate to="/" replace />;
  };
  
//   export const getUserDetail = () => {
//     const jwt = localStorage.getItem("userDetail");
//     if (jwt && jwt !== null && jwt !== "") {
//       return jwt_decode(jwt);
//     }
//     return false;
//   };
  
  