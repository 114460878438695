import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import DoordashReducer from "./DoordashReducer";

const rootReducer = combineReducers({
    DoordashReducer,
    routing: routerReducer,
});

export default rootReducer;