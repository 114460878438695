const accessToken = localStorage.getItem("accessToken");

let token = null;
if (accessToken !== "undefined" && accessToken !== null) {
    token = accessToken;
}

const initialData = {
    DoordashReducer: {
        loading: false,
        error_msg: null,
        success_msg: null,
        token: token,
        userDetail: null,
        balance: null,
        accountId: null,
        messages: null,
        storeDetail: null,
        storeTimings: null,
        storeTimingsId: null,
    },
};

export default initialData;
