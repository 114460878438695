import {
    INPROGRESS,
    IS_AUTHENTICATED,
    ERROR,
    LOGOUT,
    GET_USER,
    GET_STORE_DETAIL,
    GET_MENU_AVAILIBILITY,
    GET_STORE_GROUP
} from "./Constants";
import initialState from "./initialState";

export default function DoordashReducer(
    state = initialState.DoordashReducer,
    action
) {
    switch (action.type) {
        case INPROGRESS:
            return {
                ...state,
                error_msg: null,
                success_msg: null,
                loading: true,
            };

        case ERROR:
            return {
                ...state,
                error_msg: action.data.error_msg,
                success_msg: null,
                loading: false,
            };

        case IS_AUTHENTICATED:
            return {
                ...state,
                error_msg: null,
                success_msg: null,
                loading: false,
                // token: action.data.accessToken,
            };

        case LOGOUT:
            return {
                ...state,
                loading: false,
                error_msg: null,
                success_msg: null,
                token: null,
                isChipsActive: false,
                userDetail: null,
                balance: null,
                accountId: null,
                messages: null,
            };

        case GET_USER:
            return {
                ...state,
                error_msg: null,
                success_msg: null,
                loading: false,
                storeDetail: action.data.userDetail,
            };

        case GET_STORE_DETAIL:
            return {
                ...state,
                error_msg: null,
                success_msg: null,
                loading: false,
                storeDetail: action.data.storeDetail,
            };

        case GET_MENU_AVAILIBILITY:
            return {
                ...state,
                error_msg: null,
                success_msg: null,
                loading: false,
                storeTimings: action.data.storeTimings,
                storeTimingsId: action.data.storeTimingsId,
            };

        case GET_STORE_GROUP:
            return {
                ...state,
                error_msg: null,
                success_msg: null,
                loading: false,
                storeGroup: action.data.storeGroup,
            };
            
        default:
            return state;
    }
}
