import React from "react";
import * as storeActions from "../../../Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {Switch, Card, TimePicker} from 'antd';
import moment from 'moment';

import "./BaseCard.css";

const  BaseCard = (props) => {

    const {timeDetails} = props;

    const getDate = (date) => {
        const time = date.split(':');
        return moment().set({hour: time[0], minute: time[1]});
    };    

    const handleStartTime = (time,i) => {
        props.handleOpens(`${moment(time).format('HH')}:${moment(time).format('mm')}`, i);
    };

    const handleEndTime = (time,i) => {
        props.handleCloses(`${moment(time).format('HH')}:${moment(time).format('mm')}`, i);
    };

    return (
        
        <div className="base-day-card col-md-2">
            <Card style={{width: 300}}>
                <div className="base-day-header">
                    <div className="day-of-week">
                        {timeDetails.day_of_week}
                    </div>
                    <div className="day-status">
                        <span>Disabled</span>
                        <Switch 
                            defaultChecked={timeDetails.enabled && !!timeDetails.time_periods.length}
                            onChange={props.handleEnable}
                        />
                        <span>Enabled</span>
                    </div>
                </div>
                { timeDetails.time_periods.map((time, index) => (
                    <div key={index}>
                        <div className="base-comp">
                            <div>
                                <div className="base-ticket">
                                    <span>Opens at</span>
                                    <TimePicker use24Hours format="h:mm a" minuteStep={5} defaultValue={getDate(time.start_time)}
                                                onChange={(t) => handleStartTime(t,index)}/>
                                </div>
                                <div className="base-ticket">
                                    <span>Closes at</span>
                                    <TimePicker use24Hours format="h:mm a" minuteStep={5} defaultValue={getDate(time.end_time)}
                                                onChange={(t) => handleEndTime(t,index)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Card>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: {
        storeActions: bindActionCreators(storeActions, dispatch),
    },
});

export default connect(null, mapDispatchToProps)(BaseCard);