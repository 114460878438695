import React from "react";
import * as storeActions from "../../../Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BaseCard from "../BaseCard";
import Loader from "../../../Commons/Loader";
import { Button } from "antd";

const  BaseTab = (props) => {

    const {daysBase, loading} = props;
    return (

        loading ? <Loader isLoading={loading}/> : 
        (
            <>
            <div className="row justify-content-center">
                {
                    daysBase && daysBase.map((timeDetails, index) => ( 
                        <BaseCard
                            handleEnable={(val) => props.handleEnable(val, index, 'Main Menu')}
                            handleOpens={(startTime,pos) => props.handleOpens(startTime, pos, index, 'Main Menu')}
                            handleCloses={(endTime, pos) => props.handleCloses(endTime, pos, index, 'Main Menu')}
                            key={index} 
                            timeDetails={timeDetails} /> 
                    ))
                }
            </div>
            <Button type="primary" className="btn btn-warning save-store-time" onClick={() =>props.handleSave()}>Save</Button>
            </>
        )
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: {
        storeActions: bindActionCreators(storeActions, dispatch),
    },
});

export default connect(null, mapDispatchToProps)(BaseTab);