const ENV = "";

const ENVIRONMENT_LOCAL = {
    Base_API_URL: "http://192.168.1.8:9007/api",
};

const ENVIRONMENT_DEVELOPMENT = {
    Base_API_URL: "http://dev.driveby.guzmanygomez.com:9007/api",
};

const ENVIRONMENT_PRODUCTION = {
    Base_API_URL: "https://doordash.guzmanygomez.com:9007/api",
};

let ENVIRONMENT_VARIABLES;

if (ENV === "local") {
    ENVIRONMENT_VARIABLES = ENVIRONMENT_LOCAL;
} else if (ENV === "development") {
    ENVIRONMENT_VARIABLES = ENVIRONMENT_DEVELOPMENT;
} else {
    ENVIRONMENT_VARIABLES = ENVIRONMENT_PRODUCTION;
}

export default ENVIRONMENT_VARIABLES;
