import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './App.css';
import 'antd/dist/antd.css';
import Header from './Components/Header';
import Main from './Components/Main';
import {applyMiddleware, createStore} from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import {Provider} from 'react-redux';
import logger from 'redux-logger';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import rootReducer from './Reducers';
import initialState from './Reducers/initialState';
import { LoginedIn, RequireAuth } from './utils';
import Login from './Components/Login';

const composeEnhancers = composeWithDevTools({});

// const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, promise)));

const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, promise, logger)));

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
           <Route exact path="Login" element={<LoginedIn><Login /> </LoginedIn>} />
           <Route exact path="/" element={<RequireAuth><Header />
              <main className="ant-layout-content"  id="content-wrapper">
                <Main />
              </main>
            </RequireAuth>} />
           <Route exact path="*" element={<Login />} />
        </Routes>
      </Router>
      {/* <Header />
        <main className="ant-layout-content"  id="content-wrapper">
          <Main />
        </main> */}
    </Provider>
  );
}

export default App;
