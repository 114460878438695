import React from "react";
import * as storeActions from "../../Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Checkbox, Form } from "antd";


const storeGroup = (props) => {

    const {data} = props;

    const onCheckboxClick = (value) => {
        console.log(data);
        if(value.target.value === "isBreakfast") {
            data.isBreakfast=value.target.checked
        }
        if(value.target.value === "isCoffee") {
            data.isCoffee=value.target.checked
        }
        if(value.target.value === "hasQuesadilla") {
            data.hasQuesadilla=value.target.checked
        }
        if(value.target.value === "hasSoftServe") {
            data.hasSoftServe=value.target.checked
        }
        if(value.target.value === "hasIceCoffee") {
            data.hasIceCoffee=value.target.checked
        }
    }
  
    const handleSubmit = () => {
        props.actions.storeActions.updateStoreGroup(data)
    }
    
    return (
        <div className="setting-content">
            <div className="left-side store-group">
                <h5>Store Group ({data.StoreId})</h5>
                <div className="setting-header">
                    <Form layout="vertical">
                        <Checkbox defaultChecked={ data.isBreakfast } value="isBreakfast" onChange={onCheckboxClick}>Breakfast</Checkbox>
                        <Checkbox defaultChecked={ data.isCoffee } value="isCoffee" onChange={onCheckboxClick}>Coffee</Checkbox>
                        <Checkbox defaultChecked={ data.hasQuesadilla } value="hasQuesadilla" onChange={onCheckboxClick}>Quesadilla</Checkbox>
                        <Checkbox defaultChecked={ data.hasSoftServe } value="hasSoftServe" onChange={onCheckboxClick}>Soft Serve</Checkbox>
                        <Checkbox defaultChecked={ data.hasIceCoffee } value="hasIceCoffee" onChange={onCheckboxClick}>Ice Coffee</Checkbox>
                        <Button type="primary" onClick={()=>handleSubmit()}>Submit</Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: {
        storeActions: bindActionCreators(storeActions, dispatch),
    },
});

export default connect(null, mapDispatchToProps)(storeGroup);