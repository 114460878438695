import { Image } from 'antd';
import React from 'react';
import { clearAccessToken } from '../../utils';

function Header() {

  const Logout = () => {
    clearAccessToken();
    window.location = '/login';
  }

  return (
    <>
    <header >
        <nav className="navbar navbar-expand-lg bg-dark justify-content-between">
            <div>
                <img src="/gomex-logo.svg" alt='logo-gomex' className='gomex-logo'/>
                <Image src='/Images/logo.png' width={200} alt="dd-logo" preview={false}/>
            </div>            
            <div className='form-inline logout-tag'>
                <span onClick={()=>{Logout()}}>Logout</span>
            </div>
        </nav>
    </header>
    </>
  );
}

export default Header;
