import React from "react";
import * as storeActions from "../../../Actions";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { Tabs } from "antd";
import BaseTab from "../BaseTab";
import BreakfastTab from "../BreakfastTab";
import Setting from "../../Setting";
import StoreGroup from "../../StoreGroup";

import "./Tabpane.css";

const  Tabpane = (props) => {

    const { storeTimings, loading } = props;

    const breakfastTime = storeTimings.category_availability.find( (data) => data.title === "Breakfast" );
    const mainMenuTime = storeTimings.category_availability.find( (data) => data.title !== "Breakfast" );

    const storeReducers = useSelector((state) => ({
        storeGroup: state.DoordashReducer.storeGroup,
        storeTimings: state.DoordashReducer.storeTimings,
        storeTimingsId: state.DoordashReducer.storeTimingsId,
    }));
    console.log(storeReducers.storeGroup)

    const onSaveStoreTime = () => {
        props.actions.storeActions.updateMenuAvailibility(storeReducers.storeTimings, storeReducers.storeTimingsId)
    }

    const items = [
        { 
            label: 'Base', 
            key: '1', 
            children: <BaseTab 
                handleEnable={props.handleEnable}
                handleOpens={props.handleOpens}
                handleCloses={props.handleCloses}
                daysBase={mainMenuTime.sections}
                handleSave={onSaveStoreTime}
                loading={loading} />
        },
        {
            label: 'Breakfast',
            key: '2',
            children: breakfastTime === undefined ? null : <BreakfastTab
                handleEnable={props.handleEnable}
                handleOpens={props.handleOpens}
                handleCloses={props.handleCloses}
                daysBreakfast={breakfastTime.sections}
                handleSave={onSaveStoreTime}
                loading={loading} />,
            disabled: (breakfastTime === undefined || !storeReducers.storeGroup.isBreakfast) ? true : false
        },
        {
            label: 'Store Group',
            key: '3',
            children: <StoreGroup data={storeReducers.storeGroup}/>
        },
        {
            label: 'Sync Store',
            key: '4',
            children: <Setting storeData={props.storeData[0]} />
        },
    ];

    return (
        <>
            <Tabs className="tab-content" defaultActiveKey="1" items={items} />    
            {/* <Button>Start</Button> */}
        </>
    )

}

const mapDispatchToProps = (dispatch) => ({
    actions: {
        storeActions: bindActionCreators(storeActions, dispatch),
    },
});

export default connect(null, mapDispatchToProps)(Tabpane);