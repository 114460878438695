import React from 'react';
import { Modal } from 'antd';
import './detailModal.css';

const DetailsModal = (props) => {
    
    const { visible, messages, handleToggle, handleClearMessage } = props;
    return(
        <Modal
            title="DoorDash Live Update Stores"
            open={visible}
            onOk={handleToggle}
            onCancel={handleToggle}
            afterClose={handleClearMessage}
        >
            <React.Fragment>
                {messages.map((m, index) => (
                    <p className={m.type} key={index}>{m.message}</p>
                ))}
            </React.Fragment>
        </Modal>
    )
}

export default DetailsModal;