import axios from "axios";
import React, { useState } from "react";
import * as storeActions from "../../Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Loader from "../../Commons/Loader";
import { Input, notification, Image } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import ENVIRONMENT_VARIABLES from "../../environmental.config";
import { setAccessToken } from '../../utils';


import "./login.css";

const Login = () => {

    const [loading, setLoading] = useState(false);
    const [loginDetails, setLoginDetails] = useState({
        userName: "",
        password: "",
    });

    const handleChange = (event) => {
        const field = event.target.name;
        let commonData = { ...loginDetails };
        commonData[field] = event.target.value;
        return setLoginDetails(commonData);
    };

    const handleSubmit = () => {
        console.log(loginDetails);
        if(!loginDetails.userName || !loginDetails.password) {
            notification['error']({
                message: 'Username and Password not be an empty!',
                description: '',
            });
        }else {
            setLoading(true);
            axios.post(ENVIRONMENT_VARIABLES.Base_API_URL + '/oauth/login',
                {userId: loginDetails.userName, password:loginDetails.password})
            .then((res) => {
                setLoading(false);
                setAccessToken(res.data.accessToken);
                window.location = '/';
            }).catch((error) => {
                setLoading(false);
                notification['error']({
                    message: error.response.data.user_msg,
                    description: '',
                });
            })
        }
    }
    
    return (
        <>
        <div className="vertical-alignment-helper">
            <Loader isLoading={loading}/>
            <div className="modal-dialog vertical-align-center">
                <div className="modal-content">
                    <a href="/login" className="logo"></a>
                    <div className="modal-body">
                        <div className="row login-form ">
                            <div className="col-xs-12 text-center">
                                <h2><Image src="Images/dd.png" width={40} preview={false}/> Menu Sync Authentication</h2>
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <form>
                                            <div className="login-content">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <Input
                                                            placeholder="Enter your Username"
                                                            prefix={<UserOutlined style={{ color: '#ffd401', fontSize: 18 }}/>}
                                                            value={loginDetails.userName}
                                                            name="userName"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <Input
                                                            placeholder="Enter your Password"
                                                            prefix={<LockOutlined style={{ color: '#ffd401', fontSize: 18 }}/>}
                                                            value={loginDetails.password}
                                                            name="password"
                                                            type="password"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group text-center row save-btn">
                                                        <div className="col-xs-12 text-center">
                                                        </div>
                                                        <button type="button" className="btn btn-save"
                                                        onClick={() => handleSubmit()} >Sign In </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    actions: {
        storeActions: bindActionCreators(storeActions, dispatch),
    },
});

export default connect(null, mapDispatchToProps)(Login);
