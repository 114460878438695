import axios from "axios";
import {
    ERROR,
    INPROGRESS,
    LOGOUT,
    GET_STORE_DETAIL,
    GET_MENU_AVAILIBILITY,
    GET_STORE_GROUP,
} from "../Reducers/Constants";
import ENVIRONMENT_VARIABLES from "../environmental.config";

export const getStoreDetail = () => {
    try {
        return (dispatch) => {
            dispatch({ type: INPROGRESS });
            // const token = localStorage.getItem('accessToken');
            const api = {
                method: "GET",
                //   headers: { 'Authorization': token },
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/stores/allStores",
            };
            axios(api)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: GET_STORE_DETAIL,
                            data: {
                                storeDetail: response.data[0].storeDetails
                            },
                        });
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        if (error.response.status === 403) {
                            // clearAccessToken();
                            dispatch({
                                type: LOGOUT,
                            });
                        } else
                            dispatch({
                                type: ERROR,
                                data: {
                                    error_msg: error.response.data.message,
                                },
                            });
                    } else {
                        dispatch({
                            type: ERROR,
                            data: { error_msg: error.message.toString() },
                        });
                    }
                });
        };
    } catch (error) {
        alert(error.message.toString());
    }
};

export const getMenuAvailibility = () => {
    try {
        return (dispatch) => {
            dispatch({ type: INPROGRESS });
            const api = {
                method: "GET",
                url:
                    ENVIRONMENT_VARIABLES.Base_API_URL +
                    "/stores/MenuAvailabilityTimings",
            };

            axios(api)
                .then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: GET_MENU_AVAILIBILITY,
                            data: {
                                storeTimings: response.data[0].storeTimings,
                                storeTimingsId: response.data[0]._id
                            },
                        });
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        if (error.response.status === 403) {
                            // clearAccessToken();
                            dispatch({
                                type: LOGOUT,
                            });
                        } else
                            dispatch({
                                type: ERROR,
                                data: {
                                    error_msg: error.response.data.message,
                                },
                            });
                    } else {
                        dispatch({
                            type: ERROR,
                            data: { error_msg: error.message.toString() },
                        });
                    }
                });
        };
    } catch (error) {
        console.log(error.message.toString());
    }
};

export const getStoreGroup = (storeId) => {
    try {
        return (dispatch) => {
            dispatch({ type: INPROGRESS });
            const api = {
                method: "GET",
                url:
                    ENVIRONMENT_VARIABLES.Base_API_URL +
                    "/stores/storeGroup/"+storeId,
            };
            axios(api)
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response);
                        dispatch({
                            type: GET_STORE_GROUP,
                            data: {
                                storeGroup: response.data
                            },
                        });
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        if (error.response.status === 403) {
                            // clearAccessToken();
                            dispatch({
                                type: LOGOUT,
                            });
                        } else
                            dispatch({
                                type: ERROR,
                                data: {
                                    error_msg: error.response.data.message,
                                },
                            });
                    } else {
                        dispatch({
                            type: ERROR,
                            data: { error_msg: error.message.toString() },
                        });
                    }
                });
        }
    } catch (error) {
        console.log(error.message.toString());
    }
}

export const updateStoreGroup = (data) => {
    try {
        return (dispatch) => {
            dispatch({ type: INPROGRESS });
            const api = {
                method: 'put',
                url: 
                ENVIRONMENT_VARIABLES.Base_API_URL +'/stores/storeGroup/'+data.StoreId,
                headers: { 
                  'Content-Type': 'application/json'
                },
                data : JSON.stringify(data)
            };
            
            axios(api)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    dispatch({
                        type: GET_STORE_GROUP,
                        data: {
                            storeGroup: response.data.data
                        },
                    });
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    if (error.response.status === 403) {
                        dispatch({
                            type: LOGOUT,
                        });
                    } else
                    dispatch({
                        type: ERROR,
                        data: { error_msg: error.response.data.message, },
                    });
                } else {
                    dispatch({
                        type: ERROR,
                        data: { error_msg: error.message.toString() },
                    });
                }
            });
        }
    } catch (error) {
        console.log(error.message.toString());
    }
}

export const updateMenuAvailibility = (data, id) => {
    try {
        return (dispatch) => {
            dispatch({ type: INPROGRESS });
            const api = {
                method: 'put',
                url:
                ENVIRONMENT_VARIABLES.Base_API_URL +
                "/stores/MenuAvailabilityTimings",
                headers: { 
                  'Content-Type': 'application/json'
                },
                data : JSON.stringify({
                    id: id,
                    data: data
                })
            };

            axios(api)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: GET_MENU_AVAILIBILITY,
                        data: {
                            storeTimings: response.data[0].storeTimings,
                            storeTimingsId: response.data[0]._id
                        },
                    });
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    if (error.response.status === 403) {
                        // clearAccessToken();
                        dispatch({
                            type: LOGOUT,
                        });
                    } else
                        dispatch({
                            type: ERROR,
                            data: {
                                error_msg: error.response.data.message,
                            },
                        });
                } else {
                    dispatch({
                        type: ERROR,
                        data: { error_msg: error.message.toString() },
                    });
                }
            });
        }
    } catch (error) {
        console.log(error.message.toString());
    }
}