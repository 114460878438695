import React, { useEffect, useState } from "react";
import * as storeActions from "../../Actions";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import "./Main.css";
import { Select } from "antd";
import Tabpane from "./Tabpane";
import Loader from "../../Commons/Loader";

const Main = (props) => {

    const [storeMenuAvailibility, setStoreMenuAvailibility] = useState(null);
    const [storeData, setStoreData] = useState(null);
    const [storeOption, setStoreOption] = useState([]);
    const [selectStore, setSelectStore] = useState(null);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        props.actions.storeActions.getStoreDetail();
        props.actions.storeActions.getMenuAvailibility();
    }, [props.actions.storeActions]);

    const storeReducers = useSelector((state) => ({
        storeDetail: state.DoordashReducer.storeDetail,
        storeTimings: state.DoordashReducer.storeTimings,
        loading: state.DoordashReducer.loading,
    }));

    const onChange = (value) => {
        console.log(`selected ${value}`);
        setSelectStore(value);
        const storeDetail = storeReducers.storeDetail.filter(
            (data) => data.OrderingName === value
        );
        if (storeDetail.length && storeReducers.storeTimings) {
            setLoading(true);
            setStoreData(storeDetail);
            setStoreMenuAvailibility(
                storeReducers.storeTimings.filter(
                    (data) => data.store_id === storeDetail[0].OrderingId
                )[0]
            );
            props.actions.storeActions.getStoreGroup(storeDetail[0].OrderingId);
        }
    };

    const handleEnable = (val, position, menuTitle) => {
        
        if ( storeMenuAvailibility.category_availability.length === 2 ) {
            if (menuTitle === "Breakfast") {
                storeMenuAvailibility.category_availability[0].sections[position].enabled = val;
            }            
            if (menuTitle === "Main Menu") {
                storeMenuAvailibility.category_availability[1].sections[position].enabled = val;
            }
        } else {
            if (menuTitle === "Main Menu") {
                storeMenuAvailibility.category_availability[0].sections[position].enabled = val;
            }
        }

    }

    const handleOpens = (startTime, pos, position, menuTitle) => {
        if ( storeMenuAvailibility.category_availability.length === 2 ) {
            if (menuTitle === "Breakfast") {
                storeMenuAvailibility.category_availability[0].sections[position].time_periods[0].start_time = startTime
            }            
            if (menuTitle === "Main Menu") {
                storeMenuAvailibility.category_availability[1].sections[position].time_periods[0].start_time = startTime
            }
        } else {
            if (menuTitle === "Main Menu") {
                storeMenuAvailibility.category_availability[0].sections[position].time_periods[0].start_time = startTime;
            }
        }
    };

    const handleCloses = (endTime, pos, position, menuTitle) => {
        if ( storeMenuAvailibility.category_availability.length === 2 ) {
            if (menuTitle === "Breakfast") {
                storeMenuAvailibility.category_availability[0].sections[position].time_periods[0].end_time = endTime;
            }            
            if (menuTitle === "Main Menu") {
                storeMenuAvailibility.category_availability[1].sections[position].time_periods[0].end_time = endTime
            }
        } else {
            if (menuTitle === "Main Menu") {
                storeMenuAvailibility.category_availability[0].sections[position].time_periods[0].end_time = endTime;
            }
        }
    };

    useEffect(() => {
        let children = [];
        if (storeReducers.storeDetail !== null) {
            storeReducers.storeDetail.forEach((store) => {
                children.push({
                    value: store.OrderingName,
                    label: store.OrderingName,
                });
            });
            setStoreOption(children);
        }
    }, [storeReducers.storeDetail]);

    useEffect(() => {
        setLoading(false);
    }, [storeMenuAvailibility])

    useEffect(() => {
        setLoading(storeReducers.loading);
    }, [storeReducers.loading])

    return (
        <>
            <div className="container">
                <div className="pt-4">
                    <h6>Select Store</h6>
                    <div className="row px-2 pb-2">
                        <Select
                            className="col-md-3"
                            showSearch
                            onChange={onChange}
                            placeholder="Select Stores"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={storeOption}
                        />
                    </div>
                </div>
            </div>
            { 
                storeMenuAvailibility && selectStore ? (
                <div className="container text-center">
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8"><h2> {selectStore} </h2></div>
                        <div className="col-2"></div>
                    </div>
                </div>
                ) : null
            }
            {
                loading ? <Loader isLoading={loading}/> :
                storeMenuAvailibility ? (
                    <div className="row m-4">
                        <Tabpane 
                            loading={loading}
                            handleEnable={handleEnable}
                            handleOpens={handleOpens}
                            handleCloses={handleCloses}
                            storeTimings={storeMenuAvailibility}
                            storeData={storeData}
                                />
                    </div>
                ) : ( <div className="container">No Data Available</div>)
            }
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    actions: {
        storeActions: bindActionCreators(storeActions, dispatch),
    },
});

export default connect(null, mapDispatchToProps)(Main);
