import axios from "axios";
import React, { useState } from "react";
import * as storeActions from "../../Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "antd";
import DetailsModal from "./DetailsModal";
import Loader from "../../Commons/Loader";
import ENVIRONMENT_VARIABLES from "../../environmental.config";

const  Setting = (props) => {

    const {storeData} = props;
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);

    const syncingStore = async (storeId) => {
        setIsOpen(true);
        setMessages(current => [...current, {
            type: 'update',
            message: `Updating ${storeData.OrderingName} ...`
        }])
        await sendMenuToUber(storeId)
    }

    const sendMenuToUber = async (storeId) => {
        console.log(storeId);
        setLoading(true);
        try {
            const api = {
                method: 'post',
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/stores/storeMenuToDoordash",
                data: { id: storeId }
            };

            axios(api)
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    debugger;
                    const responseData = response.data.data;
                    if(responseData.bfastSync !== null && responseData.bfastSync.code !== 200){
                        setMessages(current => [...current,  {
                            type: 'error',
                            message: `Failed to update ${storeData.OrderingName} Breakfast Menu and Message : ${responseData.bfastSync.message}`
                        }])
                    } else {
                        setMessages(current => [...current,  {
                            type: 'success',
                            message: `Successful update ${storeData.OrderingName} Breakfast Menu...`
                        }])
                    }

                    if(responseData.mainMenuSync !== null && responseData.mainMenuSync.code !== 200){
                        setMessages(current => [...current,  {
                            type: 'error',
                            message: `Failed to update ${storeData.OrderingName} Main Menu and Message : ${responseData.bfastSync.message}`
                        }])
                    } else {
                        setMessages(current => [...current,  {
                            type: 'success',
                            message: `Successful update ${storeData.OrderingName} Main Menu...`
                        }])
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                setMessages(current => [...current,  {
                    type: 'error',
                    message: `Failed to update ${storeData.OrderingName} and Message : ${error.response.data.user_msg}`
                }])
            });

        } catch (error) {
            setLoading(false);
            setMessages(current => [...current,  {
                type: 'error',
                message: `Failed to update ${storeData.OrderingName} and Message : ${error.message.toString()}`
            }])
        }
    }

    const handleToggle = () => {
        setIsOpen(false);
        handleClearMessage();
    };

    const handleClearMessage = () => {
        setMessages([]);
    };
    
    return (
        <>
        <div className="setting-content">
            <div className="left-side">
                <div className="setting-header">
                    <h5>Store Information</h5>
                </div>
                <p>Store Id: <span>{storeData ? storeData.OrderingId : ''}</span></p>
                <p>Name: <span>{storeData ? storeData.OrderingName : ''}</span></p>
                <p>Location: <span>{storeData ? storeData.State : ''}</span></p>
            </div>
            <div className="right-side">
                <div className="integrate">     
                    <Button type="primary" onClick={() => syncingStore(storeData.OrderingId)}> Sync Store </Button>
                </div>
            </div>
        </div>
        <DetailsModal 
            visible={isOpen}
            messages={messages}
            handleToggle={handleToggle}
            handleClearMessage={handleClearMessage}
        />        
        <Loader isLoading={loading}/>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    actions: {
        storeActions: bindActionCreators(storeActions, dispatch),
    },
});

export default connect(null, mapDispatchToProps)(Setting);